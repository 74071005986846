<template>
  <b-container fluid>
    <form @submit.prevent="saveData">
      <b-row>
        <b-col sm="12">
          <card>
            <template v-slot:headerTitle>
              <h4><b>Modificar Competencia</b></h4>
            </template>
            <div class="row px-5 mt-3">
              <div class="col-xl-6">
                <div>
                  <label for=" " class="form-label">Titulo Competencia</label>
                  <input
                    v-model="item.titulo"
                    placeholder="Escriba el Nombre de la Competencia..."
                    type="text"
                    class="form-control"
                    id=" "
                  />
                </div>
              </div>
              <div class="col-xl-6">
                <div class="mb-3">
                  <label for=" " class="form-label">Tipo de Competencia</label>
                  <b-form-select
                    v-model="item.tipoCompetencia.id"
                    value-field="id"
                    text-field="descripcion"
                    :options="tipoCompetencia"
                  >
                    <template #first>
                      <b-form-select-option :value="''" disabled>
                        -- Seleccione una opcion --
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </div>
              </div>

              <div class="col-xl-12 mb-5">
                <label for=" " class="form-label">Descripción</label>
                <b-form-textarea
                  v-model="item.descripcion"
                  id="textarea-default"
                  placeholder="Escriba una descripción de la competencia..."
                ></b-form-textarea>
              </div>
            </div>
          </card>
        </b-col>
      </b-row>
      <div class="text-right px-5 pb-4 d-flex justify-content-end">
        <vs-button
          primary
          icon
          animation-type="vertical"
          type="submit"
          class="mx-1"
        >
          <i class="fas fa-save pr-1"></i>
          Guardar Cambios
          <template #animate>
            <i class="fas fa-save"></i>
          </template>
        </vs-button>
        <vs-button
          danger
          icon
          animation-type="vertical"
          class="mx-1"
          @click="$router.push('/helex/Competencias')"
        >
          <i class="fas fa-xmark pr-1"></i>
          Cancelar
          <template #animate>
            <i class="fas fa-xmark"></i>
          </template>
        </vs-button>
      </div>
    </form>
  </b-container>
</template>

<script>
export default {
  name: "DataTable",
  data() {
    return {
      id: "",
      item: {
        titulo: "",
        descripcion: "",
        tipoCompetencia: { id: "" },
        TipoCompetenciaId: "",
        EmpresaId: "",
        Estado: 0,
      },
      tipoCompetencia: [],
      usuario: {},
    };
  },
  async mounted() {
    this.$isLoading(true);
    this.id = this.$route.params.id;

    this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
    //console.log("***************", this.usuario);
    await this.getData();
    //this.item.empresa.id = this.usuario.empresa.id;
    await this.getTipoCompetencias();
    this.$isLoading(false);
  },
  methods: {
    async getTipoCompetencias() {
      try {
        let res = await this.$store.dispatch("hl_post", {
          path: "TipoCompetencia/ListTipoCompetencia/",
        });
        this.tipoCompetencia = res;

        console.log("*****-- ", this.tipoCompetencia);
      } catch (error) {
        this.tipoCompetencia = {};
        console.log("err", error);
      }
    },
    async getData() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Competencia/GetCompetencia/" + this.id,
        });
        this.item = res;

        console.log("*****-- ", this.item);
      } catch (error) {
        this.item = {};
        console.log("err", error);
      }
    },
    async saveData() {
      console.log("saving", this.item);
      try {
        //this.item.EmpresaId = this.item.empresa.id;
        // this.item.municipio.departamentoPaisId = this.item.municipio.departamentoPais.id
        this.item.Estado = this.item.estado;
        this.item.TipoCompetenciaId = this.item.tipoCompetencia.id;

        let res = await this.$store.dispatch("hl_post", {
          path: "Competencia/Update/",
          data: this.item,
        });
        console.log("post empresas...", res);
        if (res) this.$router.push("/helex/Competencias");
        else alert("Verificar");
      } catch (error) {
        //this.item = {};
        console.log("err", error);
      }
    },
  },
};
</script>
